import { DqCard, DqCardContent, DqLoader } from "@decentriq/components";
import {
  DataImportExportStatus,
  DataSourceType,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faClock,
  faExclamationCircle,
  faFileExport,
  faFileImport,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip, Typography } from "@mui/joy";
import { useMount } from "ahooks";
import { format } from "date-fns";
import { memo, useMemo } from "react";
import TimeAgo from "react-timeago";
import { DetailsGridLabel, DetailsGridValue } from "components";
import {
  dataSourceTypePresentation,
  dataTargetTypePresentation,
} from "features/datasets/models";
import { useTimeFormatter } from "hooks";
import ExternalConnectionConfiguration from "../ExternalConnectionConfiguration/ExternalConnectionConfiguration";
import {
  EXTERNAL_CONNECTIONS_TYPES,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "../ExternalConnections";
import {
  useExternalConnection,
  useExternalConnectionResult,
} from "../ExternalConnections/hooks";

export const externalConnectionStatusPresentation = new Map<
  DataImportExportStatus,
  string
>([
  [DataImportExportStatus.Success, ""],
  [DataImportExportStatus.Pending, "In progress"],
  [DataImportExportStatus.Failed, "Failed"],
]);

interface ExternalConnectionDetailsProps {
  connectionId: string | null;
}

const INVALID_JOB_ERROR_MESSAGE_REGEX = new RegExp(
  `Job is no longer valid`,
  "i"
);

const FailedStatusDetailsGridRow: React.FC<{
  connectionId: string;
}> = ({ connectionId }) => {
  const [fetchExternalConnectionResult, { error, isLoading }] =
    useExternalConnectionResult(connectionId);
  useMount(() => {
    fetchExternalConnectionResult();
  });
  const errorMessage = useMemo<string | null>(() => {
    if (!error) {
      return null;
    }
    if (INVALID_JOB_ERROR_MESSAGE_REGEX.test(error)) {
      return "The DCR has changed. The audience you are trying to exporting is not valid anymore. Please trigger another export.";
    }
    return error;
  }, [error]);
  return (
    <Stack alignItems="center" direction="row">
      <Tooltip color="danger" title="Failed">
        <Typography level="body-sm" textColor="danger.500">
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Typography>
      </Tooltip>
      {isLoading ? (
        <DqLoader label="Getting error details" />
      ) : (
        <Typography level="body-sm">{errorMessage ?? "Failed"}</Typography>
      )}
    </Stack>
  );
};

const mapDataImportExportStatusToIcon: Record<
  DataImportExportStatus,
  IconProp
> = {
  [DataImportExportStatus.Pending]: faSpinnerThird,
  [DataImportExportStatus.Failed]: faExclamationCircle,
  [DataImportExportStatus.Success]: faCheck,
};

const ExternalConnectionDetails = memo<ExternalConnectionDetailsProps>(
  ({ connectionId }) => {
    const { data, isDataReady, loading } = useExternalConnection(connectionId);
    const timeFormatter = useTimeFormatter();
    if (!isDataReady || loading) {
      return (
        <DqLoader
          size="sm"
          sx={{ height: "100%", justifyContent: "center", width: "100%" }}
        />
      );
    }
    const { type, createdAt, finishedAt, status, connectionType } = data!;
    const isExport = type === EXTERNAL_CONNECTIONS_TYPES.EXPORT;
    const pending = status === DataImportExportStatus.Pending;
    return (
      <Stack rowGap={1}>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <DetailsGridLabel label="Type" />
            <DetailsGridValue
              icon={isExport ? faFileExport : faFileImport}
              value={isExport ? "Export" : "Import"}
            />
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <DetailsGridLabel label="Status" />
            {status === DataImportExportStatus.Failed ? (
              <FailedStatusDetailsGridRow connectionId={connectionId!} />
            ) : pending ? (
              <DqLoader />
            ) : (
              <DetailsGridValue
                icon={mapDataImportExportStatusToIcon[status]}
                value={
                  <Typography level="body-sm">
                    {status === DataImportExportStatus.Success ? (
                      <>
                        Completed{" "}
                        {!!finishedAt && (
                          <TimeAgo
                            date={finishedAt}
                            formatter={timeFormatter}
                          />
                        )}
                      </>
                    ) : (
                      externalConnectionStatusPresentation.get(status)!
                    )}
                  </Typography>
                }
              />
            )}
          </DqCardContent>
        </DqCard>
        {!!createdAt && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <DetailsGridLabel label="Started at" />
              <DetailsGridValue
                icon={faClock}
                value={format(new Date(createdAt), "dd-MM-yyyy HH:mm:ss")}
              />
            </DqCardContent>
          </DqCard>
        )}
        {!!finishedAt && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <DetailsGridLabel
                label={
                  status === DataImportExportStatus.Success
                    ? "Finished at"
                    : "Failed at"
                }
              />
              <DetailsGridValue
                icon={faClock}
                value={format(new Date(finishedAt), "dd-MM-yyyy HH:mm:ss")}
              />
            </DqCardContent>
          </DqCard>
        )}
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <DetailsGridLabel
              label={isExport ? "Exported to" : "Imported from"}
            />
            <DetailsGridValue
              value={
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{ alignItems: "center" }}
                >
                  <ExternalConnectionsIcon
                    connectionType={connectionType}
                    size={ExternalConnectionsIconSize.xs}
                  />
                  {type === EXTERNAL_CONNECTIONS_TYPES.EXPORT
                    ? dataTargetTypePresentation.get(
                        connectionType as DataTargetType
                      )
                    : dataSourceTypePresentation.get(
                        connectionType as DataSourceType
                      )}
                </Stack>
              }
            />
          </DqCardContent>
        </DqCard>
        {connectionType && connectionType !== DataSourceType.Compute && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <ExternalConnectionConfiguration
                connectionId={connectionId!}
                connectionType={
                  connectionType as DataSourceType | DataTargetType
                }
              />
            </DqCardContent>
          </DqCard>
        )}
      </Stack>
    );
  }
);

ExternalConnectionDetails.displayName = "ExternalConnectionDetails";

export default ExternalConnectionDetails;
